import { computed, Ref, watch } from 'vue'

import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { ModuleNames } from '@/constants/vuex'
import { CPTSet, Network, Variable } from '@/libs/bayes'
import { SurveyStatusActionEnum } from '@/store/enums/actions'
import { Store } from '@/store/types'
import { vuexActions } from '@/store/util'
import { Assignment, ResponseSchema, SurveyStatus } from '@/types'

export default function useCompletion(
  store: Store,
  network: Ref<Network | undefined>,
  cptSet: Ref<CPTSet | undefined>,
  allocatedVariables: Ref<Array<Assignment> | undefined>,
  persistedResponses: Ref<Array<ResponseSchema> | undefined>,
  surveyStatus: Ref<SurveyStatus | undefined>
): any {
  const responseCompletionMap = computed(() => {
    const map: SurveyStatus['ext']['responseCompletionMap'] = {}
    if (allocatedVariables.value && network.value && cptSet.value && persistedResponses.value) {
      allocatedVariables.value.forEach((assignment: Assignment) => {
        const { variableId } = assignment
        const variable = network.value?.variables.find(
          (variable: Variable) => variableId === variable.id
        )
        if (variable) {
          map[variableId] = {}
          const cpt = cptSet.value?.getCPT(variable)
          if (cpt && cpt.elicitedRows && cpt.elicitedRows.length > 0) {
            for (const combinationRow of cpt.elicitedRows) {
              const rowId = combinationRow.rowId as string
              const response = persistedResponses.value?.find(
                (response: ResponseSchema) => response.rowId === rowId
              )
              map[variableId][rowId] = !!response
            }
          }
        }
      })
    }
    return map
  })

  watch(responseCompletionMap, (newVal) => {
    if (!surveyStatus.value) {
      return
    }
    let totalNum = 0
    let completedNum = 0
    Object.values(newVal).forEach((variable) =>
      Object.values(variable).forEach((completed) => {
        if (completed) {
          completedNum++
        }
        totalNum++
      })
    )
    const completionRate = totalNum ? Math.floor((completedNum / totalNum) * 100) / 100 : 0
    store.dispatch(
      vuexActions(ModuleNames.SURVEY_STATUS, SurveyStatusActionEnum.UPDATE_SURVEY_STATUS),
      {
        id: surveyStatus.value.id,
        surveyStatus: {
          [DB_FIELDS.SURVEY_STATUS.STATUS]:
            completionRate === 1
              ? DB_ENUM_VALUES.SURVEY_USER_STATUS.STATUS.COMPLETED
              : DB_ENUM_VALUES.SURVEY_USER_STATUS.STATUS.IN_PROGRESS,
          [DB_FIELDS.SURVEY_STATUS.COMPLETION_RATE]: completionRate,
          [DB_FIELDS.SURVEY_STATUS.EXT]: {
            responseCompletionMap: newVal
          }
        }
      }
    )
  })

  return {
    responseCompletionMap
  }
}

// const responseCompletionMap = computed(() => {
//   const map: SurveyStatus['ext']['responseCompletionMap'] = {}
//   allocatedVariables.value.forEach((assignment: Assignment) => {
//     const { variableId } = assignment
//     const variable = network.value.variables.find(
//       (variable: Variable) => variableId === variable.id
//     )
//     if (variable) {
//       map[variableId] = {}
//       const cpt = cptSet.value.getCPT(variable)
//       if (isCain.value) {
//         if (cpt.elicitedCombinations && cpt.elicitedCombinations.length > 0) {
//           for (const combination of cpt.elicitedCombinations) {
//             const rowId = combination[0].rowId as string
//             const response = persistedResponses.value.find(
//               (response: ResponseSchema) => response.rowId === rowId
//             )
//             map[variableId][rowId] = !!response
//           }
//         }
//       } else {
//         if (cpt.elicitedRows && cpt.elicitedRows.length > 0) {
//           for (const combinationRow of cpt.elicitedRows) {
//             const rowId = combinationRow.rowId as string
//             const response = persistedResponses.value.find(
//               (response: ResponseSchema) => response.rowId === rowId
//             )
//             map[variableId][rowId] = !!response
//           }
//         }
//       }
//     }
//   })
//   return map
// })
//
// watch(responseCompletionMap, (newVal) => {
//   let totalNum = 0
//   let completedNum = 0
//   Object.values(newVal).forEach((variable) =>
//     Object.values(variable).forEach((completed) => {
//       if (completed) {
//         completedNum++
//       }
//       totalNum++
//     })
//   )
//   const completionRate = totalNum ? Math.floor((completedNum / totalNum) * 100) / 100 : 0
//   store.dispatch(
//     vuexActions(ModuleNames.SURVEY_STATUS, SurveyStatusActionEnum.UPDATE_SURVEY_STATUS),
//     {
//       id: surveyStatus.value.id,
//       surveyStatus: {
//         [DB_FIELDS.SURVEY_STATUS.STATUS]:
//           completionRate === 1
//             ? DB_ENUM_VALUES.SURVEY_USER_STATUS.STATUS.COMPLETED
//             : DB_ENUM_VALUES.SURVEY_USER_STATUS.STATUS.IN_PROGRESS,
//         [DB_FIELDS.SURVEY_STATUS.COMPLETION_RATE]: completionRate,
//         [DB_FIELDS.SURVEY_STATUS.EXT]: {
//           responseCompletionMap: newVal
//         }
//       }
//     }
//   )
// })
