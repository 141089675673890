
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { isEmpty } from 'lodash-es'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import writeXlsxFile from 'write-excel-file'

import useCompletion from '@/components/composables/completion'
import useMethods from '@/components/composables/methods'
import { generateResponseMap } from '@/components/composables/responses'
import useSurvey from '@/components/composables/survey'
import AceTable from '@/components/method-ace/AceTable.vue'
import CainTable from '@/components/method-cain/CainTable.vue'
import { TABLE_EVENTS } from '@/components/method-common/Table'
import MarginalTable from '@/components/method-marginal/MarginalTable.vue'
import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { MESSAGE } from '@/constants/message'
import { ModuleNames } from '@/constants/vuex'
import { Variable } from '@/libs/bayes'
import { Dict } from '@/libs/common'
import { useStore } from '@/store'
import { QuestionnaireGetterEnum } from '@/store/enums/getters/questionnaire'
import { SurveyStatusStateEnum } from '@/store/enums/states'
import { vuexGetters } from '@/store/util'
import { Allocation, Assignment, ResponseSchema } from '@/types'

export default defineComponent({
  components: {
    AceTable,
    CainTable,
    MarginalTable,
    LeftOutlined,
    RightOutlined
  },
  props: {
    workspaceId: { type: String, required: true },
    surveyId: { type: String, required: true }
  },
  setup() {
    const store = useStore()
    const {
      cpt,
      cptSet,
      currentNetwork,
      currentSurvey,
      currentUser,
      network,
      hasDiscreteScale,
      selectedVariable,
      persistedResponses,
      persistResponses
    } = useSurvey(store, true)
    const {
      isCain,
      isAceOriginal,
      isAceDst,
      isAceDstSimple,
      isAceDstFull,
      isMarginal
    } = useMethods(cpt)

    const filterRegexInput = ref('')
    const isFilterByRegex = ref(false)
    const isfilterByCompletion = ref(false)

    const isOperating = ref(false)
    const surveyStatus = computed(
      () =>
        store.state[ModuleNames.SURVEY_STATUS][SurveyStatusStateEnum.SURVEY_STATUS_LIST]?.content[0]
    )
    const isPendingStatus = computed(
      () =>
        surveyStatus.value[DB_FIELDS.SURVEY_STATUS.STATUS] ===
        DB_ENUM_VALUES.SURVEY_USER_STATUS.STATUS.PENDING
    )

    const variableMap = computed(() => network.value.variableMap)
    // Handling allocated variables (navigation)

    const cptIndex = ref(0)
    const allocation = computed(
      () =>
        store.getters[
          vuexGetters(ModuleNames.QUESTIONNAIRE, QuestionnaireGetterEnum.ALLOCATION)
        ] as Allocation
    )
    const allocatedVariables = computed(() =>
      allocation.value?.assignments?.filter((assignment) => assignment.assigned)
    )
    const filteredVariables = computed(() => {
      if (!isFilterByRegex.value && !isfilterByCompletion.value) {
        return allocatedVariables.value
      }
      return allocatedVariables.value?.filter((assignment) => {
        let flag = true
        let variable = network.value?.variableMap[assignment.variableId]
        if (!variable) {
          return false
        }
        if (isFilterByRegex.value && !isEmpty(filterRegexInput.value)) {
          flag = variable.key?.match(filterRegexInput.value)
        }
        if (isfilterByCompletion.value) {
          flag = flag && !completionList.value[assignment.variableId]
        }
        return flag
      })
    })

    const variableId = computed(() =>
      allocatedVariables.value?.length > 0
        ? allocatedVariables.value[cptIndex.value].variableId
        : null
    )
    const updateSelectedVariable = () => {
      selectedVariable.value = network.value?.variables?.find(
        (variable: Variable) => variableId.value === variable.id
      )
    }
    const handlePrev = () => {
      if (cptIndex.value > 0) {
        cptIndex.value--
        updateSelectedVariable()
      }
    }
    const handleNext = () => {
      if (cptIndex.value < allocatedVariables.value?.length - 1) {
        cptIndex.value++
        updateSelectedVariable()
      }
    }
    const goQuestion = (variableId: string) => {
      for (let i = 0; i < allocatedVariables.value?.length || 0; i++) {
        if (allocatedVariables.value[i].variableId === variableId) {
          cptIndex.value = i
          break
        }
      }
      updateSelectedVariable()
    }

    watch(network, () => {
      updateSelectedVariable()
    })
    watch(variableId, () => {
      updateSelectedVariable()
    })

    const completionList = computed(() =>
      allocatedVariables.value?.reduce((acc: Dict, { variableId }) => {
        const selected = persistedResponses.value.filter(
          (response: ResponseSchema) => response.variableId === variableId
        )
        acc[variableId] = selected.length > 0
        return acc
      }, {})
    )

    const selectedVariableResponses = computed(() => {
      const variableId = selectedVariable.value?.id
      if (variableId) {
        return persistedResponses.value.filter(
          (response: ResponseSchema) => response.variableId === variableId
        )
      }
      return []
    })

    const { responseCompletionMap } = useCompletion(
      store,
      network,
      cptSet,
      allocatedVariables,
      persistedResponses,
      surveyStatus
    )

    /**
     * Persist responses for a particular selected variable
     */
    const onResponsesSave = async ({ responses }: { responses: Array<ResponseSchema> }) => {
      isOperating.value = true
      try {
        persistResponses(responses, false) // false for questionnaire
        message.success(MESSAGE.CPT_RESPONSES_SAVE_SUCCESS)
      } catch (err) {
        message.error(MESSAGE.CPT_RESPONSES_SAVE_FAIL)
        isOperating.value = false
        throw err
      }
      isOperating.value = false
    }

    const onExportTabularToExcel = async () => {
      const names: string[] = []
      const datas: any[] = []
      allocatedVariables.value.forEach((allocation: Assignment) => {
        const { variableId } = allocation
        const variable = variableMap.value[variableId]
        const cpt = cptSet.value.getCPT(variable)
        const rMap = generateResponseMap(cpt, persistedResponses.value, cpt.elicitedRows)
        datas.push(cpt.generateTabularData(rMap))
        names.push(variable.name)
      })
      await writeXlsxFile(datas, {
        sheets: names,
        fileName: currentSurvey.value?.name
      })
    }

    onMounted(() => {
      updateSelectedVariable()
      if (isPendingStatus.value) {
        message.warning(MESSAGE.QUESTIONNAIRE_SURVEY_STATUS_PENDING)
      }
    })

    return {
      filteredVariables,
      filterRegexInput,
      isFilterByRegex,
      isfilterByCompletion,
      hasDiscreteScale,
      onExportTabularToExcel,
      DB_ENUM_VALUES,
      TABLE_EVENTS,
      currentNetwork,
      cpt,
      cptSet,
      currentSurvey,
      currentUser,
      network,
      selectedVariable,
      allocatedVariables,
      handlePrev,
      handleNext,
      responseCompletionMap,
      cptIndex,
      onResponsesSave,
      selectedVariableResponses,
      isOperating,
      isPendingStatus,
      isCain,
      isAceOriginal,
      isAceDst,
      isAceDstSimple,
      isAceDstFull,
      isMarginal,
      variableMap,
      goQuestion,
      completionList
    }
  }
})
