
import { message } from 'ant-design-vue'
import { isEmpty } from 'lodash-es'
import { computed, defineComponent } from 'vue'
import { NavigationGuard, NavigationGuardWithThis, useRoute } from 'vue-router'

import Questionnaire from '@/components/questionnaire/Questionnaire.vue'
import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { MESSAGE } from '@/constants/message'
import { PATH } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import {
  AllocationActionEnum,
  NetworkActionEnum,
  QuestionnaireActionEnum,
  SurveyActionEnum,
  SurveyStatusActionEnum,
  WorkspaceActionEnum
} from '@/store/enums/actions'
import { AuthStateEnum } from '@/store/enums/states/auth'
import { QuestionnaireStateEnum } from '@/store/enums/states/questionnaire'
import { SurveyStatusStateEnum } from '@/store/enums/states/surveyStatus'
import { vuexActions } from '@/store/util'
import { Allocation } from '@/types'

const { NETWORK, SURVEY } = ModuleNames

const loadPage: NavigationGuardWithThis<undefined> | NavigationGuard = async (to, from, next) => {
  let { workspaceId, surveyId } = to.params
  if (!Array.isArray(workspaceId) && !Array.isArray(surveyId)) {
    const store = useStore()
    const userId = store.state[ModuleNames.AUTH][AuthStateEnum.USER]?.id as string
    workspaceId = workspaceId as string
    surveyId = surveyId as string
    await store.dispatch(vuexActions(NETWORK, NetworkActionEnum.GET_NETWORKS), workspaceId)
    await store.dispatch(vuexActions(SURVEY, SurveyActionEnum.GET_SURVEY), surveyId)
    await store.dispatch(
      vuexActions(ModuleNames.SURVEY_STATUS, SurveyStatusActionEnum.GET_SURVEY_STATUSES),
      {
        surveyId,
        params: {
          userId: [userId]
        }
      }
    )
    const surveyStatus =
      store.state[ModuleNames.SURVEY_STATUS][SurveyStatusStateEnum.SURVEY_STATUS_LIST]?.content[0]
    const isPendingStatus =
      !surveyStatus ||
      surveyStatus[DB_FIELDS.SURVEY_STATUS.STATUS] ===
        DB_ENUM_VALUES.SURVEY_USER_STATUS.STATUS.PENDING
    if (isPendingStatus) {
      message.warning({
        content: MESSAGE.QUESTIONNAIRE_SURVEY_STATUS_PENDING_REDIRECT,
        duration: 3
      })
      return new Promise((resolve) => {
        setTimeout(() => {
          next(PATH.ACCOUNT)
          resolve()
        }, 3000)
      })
    }
    const allocationRes = await store.dispatch(
      vuexActions(ModuleNames.ALLOCATION, AllocationActionEnum.GET_ALLOCATIONS),
      workspaceId
    )
    await store.dispatch(
      vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.GET_WORKSPACE),
      workspaceId
    )

    if (userId) {
      await store.dispatch(
        vuexActions(ModuleNames.QUESTIONNAIRE, QuestionnaireActionEnum.GET_WORKSPACE_NETWORKS),
        workspaceId
      )
      const network =
        store.state[ModuleNames.QUESTIONNAIRE][QuestionnaireStateEnum.WORKSPACE_NETWORKS]
          ?.content[0]
      if (network) {
        const networkId = network.id
        await store.dispatch(
          vuexActions(ModuleNames.QUESTIONNAIRE, QuestionnaireActionEnum.GET_DATA),
          { workspaceId, surveyId, userId, networkId }
        )
        const surveyCPTs =
          store.state[ModuleNames.QUESTIONNAIRE][QuestionnaireStateEnum.SURVEY_CPTS]?.content

        const allocationsForCurrentUser = allocationRes?.content?.filter(
          (each: Allocation) => each.userId === userId
        )

        if (isEmpty(allocationsForCurrentUser)) {
          message.error({
            content: MESSAGE.ALLOCATION_NOT_SET,
            duration: 3,
            onClose: () => window.close()
          })
          next(false)
        }

        if (surveyCPTs.length > 0) {
          next()
        } else {
          message.error({
            content: MESSAGE.CPT_NOT_SAVED,
            duration: 3,
            onClose: () => window.close()
          })
          next(false)
        }
      } else {
        message.error({
          content: MESSAGE.NETWORK_NOT_SET,
          duration: 3
        })
        next(false)
      }
    } else {
      message.error({
        content: MESSAGE.UNEXPECTED_USER_ID(userId),
        duration: 3
      })
      setTimeout(() => {
        next(PATH.HOME)
      }, 3000)
    }
  } else {
    message.error({
      content: Array.isArray(workspaceId)
        ? MESSAGE.UNEXPECTED_WORKSPACE_ID(workspaceId)
        : MESSAGE.UNEXPECTED_SURVEY_ID({ surveyId }),
      duration: 3
    })
    setTimeout(() => {
      next(PATH.HOME)
    }, 3000)
  }
}

export default defineComponent({
  components: {
    Questionnaire
  },
  beforeRouteEnter: loadPage as NavigationGuard,
  beforeRouteUpdate: loadPage as NavigationGuardWithThis<undefined>,
  setup() {
    const route = useRoute()
    const workspaceId = computed(() => {
      if (typeof route.params.workspaceId === 'string') {
        return route.params.workspaceId
      }
      return null
    })
    const surveyId = computed(() => {
      if (typeof route.params.surveyId === 'string') {
        return route.params.surveyId
      }
      return null
    })
    return {
      workspaceId,
      surveyId
    }
  }
})
